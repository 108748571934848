
const farsiWords= {
    "totalAdminUser": "تعداد کاربران ادمین",
    "ClassManagement&MonitoringSystem": "سیستم مدیریت و مانیتورینگ کلاس"
    ,
    "dashboard": "داشبورد",
    "userProfile": "مشخصات کاربر",
    "setting": "تنظیمات",
    "report": "راپور"
}


export default farsiWords;